import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { defaultCameraImage } from "utils/camera";
import Heatmap from "visual-heatmap";
import useAPI from "services/ApiService";
import { noImage } from "utils/static";
import { getVehicleTypes } from "config/VehicleTypes";
import VehicleFilter from "./VehicleFIlter";
import Loader from "react-spinners/BarLoader";
import { LoadingWrapper } from "components/LoadingWrapper/LoadingWrapper";
import { useQueryLoading } from "hooks/useLoading";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { isDateRangeMoreThan30Days } from "utils/functions";
import toast from "react-hot-toast";

function HeatmapComp({ camera, image, imageUrl, vehicleTrajectoryHeatMap, localRange, isLast24, setisLast24, dateRange }) {
  // console.log("image", image);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const instanceRef = useRef(null);
  const resizeTimeoutRef = useRef(null);
  const api = useAPI();
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const [filterVehicleType, setFilterVehicleType] = useState(
    getVehicleTypes(true).reduce((acc, type) => {
      acc[type.type] = true;
      return acc;
    }, [])
  );

  // Filter for vehicle types
  const vehicleFilter = VehicleFilter(filterVehicleType, setFilterVehicleType);

  const [bgImage, bgImageLoading] = useQueryLoading(`${imageUrl}`, () => (imageUrl ? api.getImage(imageUrl) : null));
  // console.log(bgImage
  // )

  const updateSize = useCallback(() => {
    if (containerRef.current && image && image.width && image.height) {
      const { width } = containerRef.current.getBoundingClientRect();
      const aspectRatio = image.height / image.width;
      const height = width * aspectRatio;
      setContainerSize({ width, height });
    }
  }, [image]);

  useEffect(() => {
    updateSize();

    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeTimeoutRef.current = setTimeout(() => {
        updateSize();
      }, 250); // Debounce for 250ms
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [updateSize]);

  const getHeatmapData = useCallback(() => {
    if (!containerSize.width || !containerSize.height || !vehicleTrajectoryHeatMap) return [];

    let data = [];
    vehicleTrajectoryHeatMap.forEach((vehicle) => {
      const vehicleType = vehicle.vehicle_type.type;
      if (filterVehicleType[vehicleType]) {
        vehicle.heatmap.forEach((point) => {
          data.push({
            x: point.x * containerSize.width,
            y: point.y * containerSize.height,
            value: Math.log(point.value + 1) * 100,
            vehicleType: vehicleType
          });
        });
      }
    });
    return data;
  }, [containerSize, filterVehicleType, vehicleTrajectoryHeatMap]);

  const vehicleTypeCount = useMemo(() => {
    if (!vehicleTrajectoryHeatMap) return;
    const counts = {};
    vehicleTrajectoryHeatMap.forEach((vehicle) => {
      counts[vehicle.vehicle_type.type] = vehicle.trajectory_count;
    });
    return counts;
  }, [vehicleTrajectoryHeatMap]);

  // Initialize Heatmap instance when bgImage or containerSize changes
  useEffect(() => {
    if (!canvasRef.current || !bgImage || !containerSize.width || !containerSize.height) return;

    // Cleanup existing instance if any
    if (instanceRef.current) {
      instanceRef.current = null;
      canvasRef.current.innerHTML = ""; // Remove existing canvas element
    }

    instanceRef.current = Heatmap(canvasRef.current, {
      size: 20.0,
      min: 0,
      intensity: 1.0,
      backgroundImage: {
        url: bgImage,
        x: 0,
        y: 0
      },
      gradient: [
        { color: [0, 0, 0, 0.0], offset: 0 },
        { color: [0, 0, 255, 0.2], offset: 0.2 },
        { color: [0, 255, 0, 0.5], offset: 0.45 },
        { color: [255, 255, 0, 1.0], offset: 0.85 },
        { color: [255, 0, 0, 1.0], offset: 1.0 }
      ]
    });

    // Render initial data
    const data = getHeatmapData();
    instanceRef.current.renderData(data);
  }, [bgImage, containerSize]);

  // Update heatmap data when filters change
  useEffect(() => {
    if (instanceRef.current) {
      const data = getHeatmapData();
      instanceRef.current.clear();
      instanceRef.current.renderData(data);
    }
  }, [getHeatmapData]);

  const handleToggle = () => {
    const invalidDate = isDateRangeMoreThan30Days(dateRange);

    if (invalidDate) {
      toast("Trajectory data is limited to a maximum of 30 days. Please change the date range to 30 days or less and try again.", {
        duration: 10000
      });
      return;
    }
    setisLast24(false);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div className="col-md-9" style={{ padding: 0 }} ref={containerRef}>
        <LoadingWrapper state={bgImageLoading}>
          <div
            ref={canvasRef}
            style={{
              width: "100%",
              height: `${containerSize.height}px`,
              maxHeight: `${image.height}px`
            }}
            className="relative"
          />
        </LoadingWrapper>
      </div>
      <div className="col-md-3">
        <div className="legend card">
          {getVehicleTypes(true).map((type) => (
            <label key={type.type} className="item" onDoubleClick={() => vehicleFilter.one(type.type)}>
              <input
                type="checkbox"
                name="VehicleType"
                checked={filterVehicleType[type.type]}
                onChange={(event) => vehicleFilter.set(type.type, event.target.checked)}
              />
              <em style={{ background: type.color }} />
              <span>
                {type.plural}
                {` (${vehicleTypeCount[type.type] ?? 0})`}
              </span>
            </label>
          ))}

          <div className="select-controls">
            <span onClick={() => vehicleFilter.all(true)}>select all</span>
            &nbsp; / &nbsp;
            <span onClick={() => vehicleFilter.all(false)}>none</span>
          </div>
        </div>
        {!localRange?.isLessthan24 && (
          <div style={{ width: "fit-content" }}>
            {isLast24 ? (
              <div onClick={handleToggle} style={{ cursor: "pointer" }}>
                <BsToggleOn className="toggle active" />
                <span>Show only data for the last 24hrs of the chosen date range. </span>
              </div>
            ) : (
              <div onClick={() => setisLast24(true)} style={{ cursor: "pointer" }}>
                {" "}
                <BsToggleOff className="toggle" />
                <span>Show only data for the last 24hrs of the chosen date range.</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeatmapComp;
